import type { Item } from 'src/sdk/checkout/data/types'
import { parseStringPrice } from 'src/utils/checkout/cart'

interface ProductFlagProps {
  item: Item
}

export const ProductFlag = ({ item }: ProductFlagProps) => {
  const { seller, listPrice, price } = item

  const parsedListPrice = parseStringPrice(listPrice)
  const parsedPrice = parseStringPrice(price)

  const showCashbackFlag = seller === '1'
  const hasDiscount = parsedListPrice > parsedPrice

  const discountPercentage =
    ((parsedListPrice - parsedPrice) / parsedListPrice) * 100

  const parsedDiscountPercentage = Math.round(discountPercentage)

  return (
    <div className="absolute flex font-inter z-[0]">
      {hasDiscount && (
        <span className="h-[18px] flex items-center text-[10px] font-medium bg-yellow-300 px-1">
          {parsedDiscountPercentage}% OFF
        </span>
      )}
      {showCashbackFlag && (
        <span className=" h-[18px] flex items-center text-[10px] font-medium bg-mint px-1">
          Cashback
        </span>
      )}
    </div>
  )
}
