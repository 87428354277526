interface InformationIconProps {
  color?: string
  width?: number
  height?: number
}

export function InformationIcon({
  color = '#007cb9',
  width = 14,
  height = 14,
}: InformationIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33301 8.00004C1.33301 11.682 4.31767 14.6667 7.99967 14.6667C11.6817 14.6667 14.6663 11.682 14.6663 8.00004C14.6663 4.31804 11.6817 1.33337 7.99967 1.33337C4.31767 1.33337 1.33301 4.31804 1.33301 8.00004ZM13.333 8.00004C13.333 10.9456 10.9452 13.3334 7.99967 13.3334C5.05416 13.3334 2.66634 10.9456 2.66634 8.00004C2.66634 5.05452 5.05416 2.66671 7.99967 2.66671C10.9452 2.66671 13.333 5.05452 13.333 8.00004ZM8.66634 10V11.3334H7.33301V10H8.66634ZM8.66634 8.66671V4.66671H7.33301V8.66671H8.66634Z"
        fill={color}
      />
    </svg>
  )
}
